import React from 'react';
import data from '../utils/policy.json';

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

import Section from "../components/Section";

export default () => {
  return (
    <Section
      id="policy"
      caption="プライバシーポリシー"
      h1="Privacy Policy"
    >
      <Container maxWidth="md" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <List>
          {data.map(({ title, list, body }, i) => (
            <Box key={i}>
              {!!title && (
                <Typography variant="h2" gutterBottom>
                  {title}
                </Typography>
              )}
              {!!body && (
                <Typography variant="body2" textAlign="left" gutterBottom>
                  {body}
                </Typography>
              )}
              {!!list && (
                <List sx={{ marginLeft: !!data[i - 1].body ? 2 : 0 }}>
                  {list.map((item, j) => (
                    <Typography key={j} variant="body2" textAlign="left" gutterBottom>
                      {item}
                    </Typography>
                  ))}
                </List>
              )}
            </Box>
          ))}
        </List>
      </Container>
    </Section>
  );
}
